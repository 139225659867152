import React from "react"
import { StaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"

const BgImg = styled(BackgroundImage)`
  display: flex;
  min-height: 500px;
  width: 100%;
  transform: scale(1);
  transition: 0.3s transform;
  border-radius: 6px;
  overflow: hidden;
`

const Content = styled.span`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 24px 20px 0;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 24px 40px 0;
  }
`

const MfePost = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          desktop: file(relativePath: { eq: "lion.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => {
        const imageData = data.desktop.childImageSharp.fluid
        return (
          <>
            <BgImg Tag="div" fluid={imageData} />
            <Content>
              <h3>Micro Frontends in the Wild</h3>
              <p>
                Micro frontends have emerged as a new architectural approach for
                building out frontends at scale...
              </p>
            </Content>
          </>
        )
      }}
    />
  )
}

export default MfePost
