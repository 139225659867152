import React from "react"
import styled, { css } from "styled-components"

interface RootProps {
  readonly size?: "sm" | "md" | "lg"
  readonly variant?: "primary" | "secondary" | "tertiary"
}

interface ButtonProps extends RootProps {
  children: React.ReactNode
  onClick?: (e: React.MouseEvent) => void
  element?: "anchor" | "button"
  disabled?: boolean
  className?: string
}

const root = css<RootProps>`
  display: inline-flex;
  background-color: transparent;
  position: relative;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  z-index: 1;
  cursor: pointer;
  border: none;
  letter-spacing: 1px;
  text-transform: uppercase;
  ${props =>
    props.variant === "tertiary" && `color: ${props.theme.colors.white};`}
  padding: ${props => {
    switch (props.size) {
      case "sm":
        return "10px 20px"
      case "md":
        return "12px 20px"
      case "lg":
        return "14px 26px"
      default: {
        return "10px 20px"
      }
    }
  }};

  & > svg {
    margin-left: 8px;
    transition: transform 0.2s ease-in-out;
    transform: translateX(0);
  }

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: block;
    border-radius: 4px;
    transition: transform 0.2s ease-in-out;
    transform: scale(1);
    ${props => {
      switch (props.variant) {
        case "primary":
          return `
            background-color: ${props.theme.colors.greenLt};
            border: 2px solid ${props.theme.colors.greenLt};
          `
        case "secondary":
          return `
            background-color: ${props.theme.colors.white};
            border: 2px solid ${props.theme.colors.white};
          `
        case "tertiary": {
          return `
            background-color: transparent;
            border: 2px solid ${props.theme.colors.white};
          `
        }
        default: {
          return `
            background-color: ${props.theme.colors.white};
            border: 2px solid ${props.theme.colors.white};
          `
        }
      }
    }};
  }

  &:disabled::before {
    background-color: ${props => props.theme.colors.grayLt};
  }

  &:hover {
    background-color: ${props => {
      switch (props.variant) {
        case "tertiary": {
          return `rgba(0,0,0, 0.2)`
        }
        default: {
          return props.theme.colors.greenDk
        }
      }
    }};

    &::before {
      transform: scale(1.05);
      ${({ theme }) => theme.mixins.shadow}
    }

    & > svg {
      transform: translateX(4px);
    }
  }
`

const RootBtn = styled.button`
  ${root}
`

const RootAnchor = styled.a<RootProps>`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.grayDk};
  ${root}
`

const Button: React.SFC<ButtonProps> = ({
  size,
  variant,
  children,
  element,
  ...attrs
}) =>
  element === "anchor" ? (
    <RootAnchor size={size} variant={variant} {...attrs}>
      {children}
    </RootAnchor>
  ) : (
    <RootBtn size={size} variant={variant} {...attrs}>
      {children}
    </RootBtn>
  )

Button.defaultProps = {
  size: "md",
  variant: "primary",
}

export default Button
