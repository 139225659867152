import React, { useState, useEffect } from "react"
import { StaticQuery, graphql } from "gatsby"
import { debounce } from "lodash"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"

const BgImg = styled(BackgroundImage)`
  height: 100%;
  width: 100%;
  transform: scale(1.05) translate(0, 0);
  transition: 0.5s transform;
  top: 4px;
  position: absolute !important;
`

const Content = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  padding: 24px 20px 0;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 24px 40px 0;
  }
`

const HeroBackground = ({ children }) => {
  const [translateX, setTranslateX] = useState("-14px")
  const [translateY, setTranslateY] = useState("-8px")

  useEffect(() => {
    const mouseDebounced = debounce(handleMouseMove, 10)
    document.addEventListener("mousemove", mouseDebounced)
    return () => document.removeEventListener("mousemove", mouseDebounced)
  }, [])

  const handleMouseMove = e => {
    setTranslateX(`${-e.offsetX / 60 - 2}px`)
    setTranslateY(`${-e.offsetY / 60 - 2}px`)
  }

  return (
    <StaticQuery
      query={graphql`
        query {
          desktop: file(relativePath: { eq: "hero-bg.png" }) {
            childImageSharp {
              fluid(maxWidth: 1440, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => {
        const imageData = data.desktop.childImageSharp.fluid
        return (
          <>
            <Content>{children}</Content>
            <BgImg
              Tag="section"
              fluid={imageData}
              style={{
                transform: `scale(1.05) translate(${translateX},${translateY})`,
              }}
            />
          </>
        )
      }}
    />
  )
}

export default HeroBackground
