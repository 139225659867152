import React from "react"
import { StaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"

const BgImg = styled(BackgroundImage)`
  display: flex;
  min-height: 500px;
  width: 100%;
  transform: scale(1);
  transition: 0.3s transform;
  border-radius: 6px;
  overflow: hidden;
`

const Content = styled.span`
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 640px;
  padding: 24px 20px 0;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 24px 40px 0;
  }
`
const TinaPost = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          desktop: file(relativePath: { eq: "llama.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => {
        const imageData = data.desktop.childImageSharp.fluid
        return (
          <>
            <BgImg Tag="div" fluid={imageData} />
            <Content>
              <h3>
                Provision, setup, and secure a TinaCMS cloud editor on AWS
              </h3>
              <p>
                This tutorial will guide you through the setup, provisioning and
                securing the TinaCMS cloud editor with Gatsby and AWS.
              </p>
            </Content>
          </>
        )
      }}
    />
  )
}

export default TinaPost
