import React from "react"
import styled from "styled-components"

interface RootProps {
  size?: "sm" | "lg"
}

interface CloseBtnProps extends RootProps {
  handleClick: () => void
}

const Root = styled.button<RootProps>`
  cursor: pointer;
  position: absolute;
  top: 34px;
  right: 20px;
  transition: transform 0.3s, background-color 0.3s;
  transform: scale(1);
  background-color: white;
  border-color: transparent;
  border-radius: 50%;
  display: block;
  width: ${({ size }) => (size === "sm" ? "20px" : "40px")};
  height: ${({ size }) => (size === "sm" ? "20px" : "40px")};
  &:before,
  &:after {
    content: "";
    border-radius: 2px;
    height: 1.5px;
    width: 12px;
    position: absolute;
    background-color: ${({ theme }) => theme.colors.grayDk};
    top: 50%;
    left: 50%;
    margin-top: -0.75px;
    margin-left: -6px;
    display: block;
    transition: background-color 0.3s;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.grayLt};
    transform: scale(1.1);
  }
  span {
    ${props => props.theme.mixins.visuallyHidden}
  }
`

const CloseBtn: React.SFC<CloseBtnProps> = ({ handleClick, size = "sm" }) => (
  <Root onClick={handleClick} size={size}>
    <span>close</span>
  </Root>
)

export default CloseBtn
