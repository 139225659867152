import styled from "styled-components"

export default styled.span`
  display: block;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.greenLt};
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fontSize.h4};
  letter-spacing: 3px;
  font-weight: bold;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: ${({ theme }) => theme.fontSize.h3};
  }
`
