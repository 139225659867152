import React, { useState, useRef } from "react"
import styled, { AnyStyledComponent } from "styled-components"
import FocusLock from "react-focus-lock"
import scrollTo from "gatsby-plugin-smoothscroll"

import { useOnClickOutside } from "../hooks/useOnClickOutside"
import Hamburger from "./Hamburger"
import MobileMenu from "./MobileMenu"
const Logo = require("../images/logo.svg") as AnyStyledComponent

interface HeaderProps {
  siteTitle: string
}

const Root = styled.header`
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
`

const AppLogo = styled(Logo)`
  display: inline-flex;
  width: 250px;
`

const LogoWrapper = styled.div`
  width: 56px;
  overflow: hidden;
  transition: 0.3s ease width;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 250px;
  }
`

const Nav = styled.ul`
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: flex;
    align-items: center;
    ${props => props.theme.mixins.zeroList}
  }
`

const NavItem = styled.li`
  margin-bottom: 0;
  margin-left: 40px;
  &:first-child {
    margin-left: 0;
  }
  & > a {
    color: ${props => props.theme.colors.white};
    text-decoration: none;
    transition: 0.2s color ease-out;
    text-transform: uppercase;
    letter-spacing: 1px;

    &:hover,
    &.active {
      color: ${props => props.theme.colors.greenLt};
    }
  }
`

const Hidden = styled.span`
  ${props => props.theme.mixins.visuallyHidden}
`

const MobileNav = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`

const Header: React.SFC<HeaderProps> = ({ siteTitle }) => {
  const [open, setOpen] = useState(false)
  const node = useRef<HTMLDivElement>(null)
  const menuId = "main-menu"

  useOnClickOutside(node, () => setOpen(false))

  return (
    <Root>
      <h1 style={{ lineHeight: 0, margin: 0, padding: 0 }}>
        <a
          href="#top"
          onClick={e => {
            e.preventDefault()
            scrollTo("#top")
          }}
        >
          <Hidden>{siteTitle}</Hidden>
          <LogoWrapper>
            <AppLogo />
          </LogoWrapper>
        </a>
      </h1>
      <Nav>
        <NavItem>
          <a
            href="#services"
            onClick={e => {
              e.preventDefault()
              scrollTo("#services")
            }}
          >
            Services
          </a>
        </NavItem>
        <NavItem>
          <a
            href="#thoughts"
            onClick={e => {
              e.preventDefault()
              scrollTo("#thoughts")
            }}
          >
            Thoughts
          </a>
        </NavItem>
        <NavItem>
          <a
            href="#about"
            onClick={e => {
              e.preventDefault()
              scrollTo("#about")
            }}
          >
            About
          </a>
        </NavItem>
        <NavItem>
          <a
            target="_blank"
            href="mailto:info@integralstack.io?subject=Contact Inquiry"
          >
            Contact
          </a>
        </NavItem>
      </Nav>
      <MobileNav ref={node}>
        <FocusLock disabled={!open}>
          <Hamburger open={open} setOpen={setOpen} aria-controls={menuId} />
          <MobileMenu open={open} setOpen={setOpen} id={menuId} />
        </FocusLock>
      </MobileNav>
    </Root>
  )
}

export default Header
