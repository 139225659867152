// Burger.js
import React from "react";
import styled from "styled-components";

interface HamburgerProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const Root = styled.button`
  top: 20px;
  right: 20px;
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: space-around;
  width: 40px;
  height: 40px;
  background: ${({ theme }) => theme.colors.white};
  border: none;
  cursor: pointer;
  z-index: 1000;
  border-radius: 50%;
  transform: scale(1);
  transition: transform 0.3s, background-color 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grayLt};
    transform: scale(1.1);
  }

  div {
    width: 20px;
    height: 3px;
    background: ${({ theme }) => theme.colors.grayDk};
    border-radius: 2px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
  }
`;

const Hamburger: React.SFC<HamburgerProps> = ({ open, setOpen, ...props }) => {
  const isExpanded = open ? true : false;

  return (
    <Root
      aria-label="Toggle menu"
      aria-expanded={isExpanded}
      onClick={() => setOpen(!open)}
      {...props}
    >
      <div />
      <div />
      <div />
    </Root>
  );
};

export default Hamburger;
