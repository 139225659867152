import React from "react"
import styled, { AnyStyledComponent } from "styled-components"
import scrollTo from "gatsby-plugin-smoothscroll"

const Logo = require("../images/logo-sm.svg") as AnyStyledComponent
import CloseBtn from "./CloseBtn"

interface RootProps {
  readonly open: boolean
}

interface MobileMenuProps extends RootProps {
  setOpen: (open: boolean) => void
  id: string
}

const Root = styled.nav<RootProps>`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.blueDk};
  min-width: 300px;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(101%)")};
  height: 100vh;
  text-align: left;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  transition: transform 0.3s ease-in-out;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex["3"]};

  a {
    padding: 30px 0;
    font-weight: bold;
    text-decoration: none;
    transition: color 0.3s linear;
    border-bottom: 3px solid ${({ theme }) => theme.colors.blueLt};
    color: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => theme.fontSize.h3};
    transition: 0.3s background-color ease-in-out;

    span {
      display: block;
      transform: scale(1);
      transition: transform 0.2s ease-in-out;
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.greenLt};
      color: ${({ theme }) => theme.colors.grayDk};
      color: span {
        transform: scale(1.1);
      }
    }
  }

  nav {
    display: flex;
    flex-direction: column;
    height: 100%;
    border-top: 3px solid ${({ theme }) => theme.colors.blueLt};
  }
`

const AppLogo = styled.div`
  margin: 30px auto;
  width: 60px;
  svg {
    width: 100%;
    height: 100%;
  }
`

const MobileMenu: React.SFC<MobileMenuProps> = ({
  open,
  setOpen,
  ...props
}) => {
  const isHidden = open ? true : false
  const tabIndex = isHidden ? 0 : -1

  return (
    <Root open={open} aria-hidden={!isHidden} {...props}>
      <CloseBtn size="lg" handleClick={() => setOpen(false)} />
      <AppLogo>
        <Logo />
      </AppLogo>
      <nav>
        <a
          href="#services"
          tabIndex={tabIndex}
          onClick={e => {
            e.preventDefault()
            scrollTo("#services")
            setOpen(false)
          }}
        >
          Services
        </a>
        <a
          href="#thoughts"
          tabIndex={tabIndex}
          onClick={e => {
            e.preventDefault()
            scrollTo("#thoughts")
            setOpen(false)
          }}
        >
          Thoughts
        </a>
        <a
          href="#about"
          tabIndex={tabIndex}
          onClick={e => {
            e.preventDefault()
            scrollTo("#about")
            setOpen(false)
          }}
        >
          About
        </a>
        <a
          target="_blank"
          href="mailto:info@integralstack.io?subject=Contact Inquiry"
        >
          Contact
        </a>
      </nav>
    </Root>
  )
}

export default MobileMenu
