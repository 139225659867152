import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import scrollTo from "gatsby-plugin-smoothscroll"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroBg from "../components/HeroBg"
import Header from "../components/Header"
import SmallTitle from "../components/SmallTitle"
import Button from "../components/Button"
import MfePost from "../components/MfePost"
import TinaPost from "../components/TinaPost"
import ArrowIcon from "../images/arrow.svg"
import meImg from "./me4-lg.jpg"
import mayImg from "./may-lg.jpg"
import leoImg from "./leo-lg(BW).jpg"
import linkedIn from "./linkedin.svg"

const Hero = styled.header`
  position: relative;
  width: 100%;
  min-height: 100vh;
`

const HeroText = styled.div`
  height: 100%;
  margin-top: 120px;
  margin-bottom: 120px;
  max-width: 680px;

  & > button:not(:last-child) {
    margin-right: 24px;
  }
`

const HeroTitle = styled.h1`
  margin-top: 24px;
  margin-bottom: 40px;
`

const Consultation = styled.section`
  position: relative;
  text-align: center;
  padding: 60px 20px;
  margin-bottom: 120px;

  & > h3 {
    color: ${({ theme }) => theme.colors.greenLt};
  }

  & > h2,
  & > p {
    margin: 0 auto;
    max-width: 600px;
  }

  & p {
    margin-top: 30px;
  }

  & > * {
    position: relative;
    z-index: 2;
  }

  &::before {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.blueLt};
    transform: skewY(2deg);
    z-index: 0;
    border-top: 1px solid ${({ theme }) => theme.colors.greenLt};
    border-bottom: 1px solid ${({ theme }) => theme.colors.greenLt};
  }
`

const ConsulationBtn = styled(Button)`
  position: absolute;
  left: 50%;
  bottom: -20px;
  margin-left: -109px;
`

const Section = styled.section`
  padding: 20px;
  margin-bottom: 80px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 20px 40px;
  }
`

const ServiceWrapper = styled.ul`
  margin: 0 auto;
  max-width: 1200px;
  padding: 60px 0 20px;
  display: flex;
  flex-wrap: wrap;
`

const LeadText = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-size: 20px;
  font-weight: lighter;
  padding: 0 20px 40px;
  max-width: 960px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 26px;
  }
`

const Service = styled.li`
  padding: 20px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 40px 20px;
    width: 50%;
  }

  & > h3 {
    margin-bottom: 10px;
    color: ${({ theme }) => theme.colors.greenLt};
  }
`

const PostsWrapper = styled.ol`
  list-style: none;
  display: flex;
  margin: 0 auto;
  padding: 60px 0;
  flex-wrap: wrap;
  max-width: 1360px;
`

const SectionTitle = styled.h2`
  color: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fontSize.h4};
  letter-spacing: 3px;
  display: flex;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: ${({ theme }) => theme.fontSize.h3};
  }

  &::before {
    content: "";
    width: 20px;
    height: 4px;
    margin-right: 20px;
    display: block;
    background-color: ${({ theme }) => theme.colors.greenLt};
  }
`

const Post = styled.li`
  padding: 20px;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 50%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: ${({ size }) =>
      size === "lg" ? "calc(200% / 3)" : "calc(100% / 3)"};
  }

  & > a {
    position: relative;
    text-decoration: none;
    background-color: ${({ theme }) => theme.colors.blueLt};
    display: flex;
    border-radius: 6px;
    overflow: hidden;
    ${({ theme }) => theme.mixins.shadow}

    & h3,
    & p {
      position: relative;
      z-index: 1;
    }

    &:hover h3 {
      color: ${({ theme }) => theme.colors.greenLt};
    }

    &:hover > div {
      transform: scale(1.1) rotate(-3deg);
    }

    &:hover::before {
      background-color: rgba(0, 0, 0, 0.4);
    }

    &::before {
      content: "";
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0);
      display: block;
      position: absolute;
      width: 100%;
      z-index: 1;
      transition: 0.3s background-color;
    }
  }

  & h3 {
    transition: 0.3s color;
  }

  & p {
    color: ${({ theme }) => theme.colors.grayLt};
    margin-top: 20px;
  }
`

const AboutWrapper = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  padding: 60px 0 20px;
`

const TeamWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1000px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: row;
    align-items: center;
  }
`

const Headshot = styled.a`
  position: relative;
  display: block;
  width: 240px;
  height: 240px;
  border-radius: 50%;
  overflow: hidden;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: row;
  }

  & > img:first-child {
    width: 240px;
    height: 240px;
    border-radius: 50%;
    transform: scale(1) rotate(0deg);
    transition: 0.3s transform;
  }

  & > img:last-child {
    position: absolute;
    z-index: 2;
    top: 120px;
    left: 120px;
    margin-left: -20px;
    margin-top: -20px;
    width: 40px;
    height: 40px;
    transform: translateY(160px);
    transition: 0.15s transform ease-in;
  }

  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    display: block;
    background-color: rgba(0, 0, 0, 0);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transition: 0.3s background-color;
  }

  &:hover::before {
    background-color: rgba(0, 0, 0, 0.4);
  }

  &:hover img:first-child {
    transform: scale(1.1) rotate(-3deg);
  }

  &:hover img:last-child {
    transform: translateY(0);
  }
`

const AboutText = styled.div`
  padding: 20px;
  max-width: 740px;
  margin: 0 auto;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-left: 20px;
  }

  & > h3 {
    color: ${({ theme }) => theme.colors.greenLt};
  }

  & > span {
    color: ${({ theme }) => theme.colors.white};
    display: block;
    line-height: 1.2;
    margin-bottom: 18px;
  }
`

const HeroBtn = styled(Button)`
  width: 100%;
  margin-top: 10px;
  justify-content: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: auto;
    margin-top: 0;
  }
`

const BlogIndex = ({ data, location }) => {
  const siteTitle = `Integral Stack - Software and Leadership`
  // const posts = data.allMarkdownRemark.nodes

  return (
    <Layout location={location} title={siteTitle} id="top">
      <SEO title="Home" />
      <Hero>
        <HeroBg>
          <Header siteTitle="Integral Stack" />
          <HeroText>
            <SmallTitle>Consulting &amp; Leadership</SmallTitle>
            <HeroTitle>
              Web &amp; Mobile
              <br /> Software Development
            </HeroTitle>
            <HeroBtn
              variant="primary"
              size="lg"
              onClick={() => scrollTo("#services")}
            >
              Our Services
            </HeroBtn>
            <HeroBtn
              variant="tertiary"
              size="lg"
              element="anchor"
              target="_blank"
              href="https://calendly.com/senor-sean/integral-stack-software-consultation"
            >
              Contact Us <ArrowIcon />
            </HeroBtn>
          </HeroText>
        </HeroBg>
      </Hero>
      <Section id="services">
        <SectionTitle>Services</SectionTitle>
        <ServiceWrapper>
          <LeadText>
            We can help you launch a new product or seamlessly integrate with
            your development team to accelerate product delivery. In either
            case, we build software with relentless attention to quality, modern
            development techniques, and leading technology.
          </LeadText>
          <Service>
            <h3>Full-stack Development</h3>
            <p>
              Software as a service, single page apps, mobile apps,
              microservices, API design, quality assurance &amp; testing
            </p>
          </Service>
          <Service>
            <h3>Web3 &amp; Blockchain Development</h3>
            <p>
              DAPP development, Hyperledger, smart contracts, Cryptocurrency
            </p>
          </Service>
          <Service>
            <h3>DevOps &amp; Platform architecture</h3>
            <p>
              Containerization, container orchestration, cloud architecture,
              CI/CD pipelines
            </p>
          </Service>
          <Service>
            <h3>Consulting &amp; Training</h3>
            <p>
              Architecture guidance, team training, software development
              consulting, digital transformation
            </p>
          </Service>
        </ServiceWrapper>
      </Section>
      <Section id="thoughts">
        <SectionTitle>Thoughts</SectionTitle>
        <PostsWrapper style={{ listStyle: `none` }}>
          <Post size="lg">
            <a
              href="https://levelup.gitconnected.com/provision-setup-and-secure-a-tinacms-cloud-editor-on-aws-e96b0e060e7c"
              rel="nofollow noopener"
              target="_blank"
            >
              <TinaPost />
            </a>
          </Post>
          <Post>
            <a
              href="https://youtu.be/BKl2r-XVfIU?t=3135"
              rel="nofollow noopener"
              target="_blank"
            >
              <MfePost />
            </a>
          </Post>
          {/* {posts.map(post => {
            const title = post.frontmatter.title || post.fields.slug

            return (
              <Post key={post.fields.slug}>
                <Link to={post.fields.slug} itemProp="url">
                  <article
                    className="post-list-item"
                    itemScope
                    itemType="http://schema.org/Article"
                  >
                    <header>
                      <h3>
                        <span itemProp="headline">{title}</span>
                      </h3>
                      <small>{post.frontmatter.date}</small>
                    </header>
                    <section>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: post.frontmatter.description || post.excerpt,
                        }}
                        itemProp="description"
                      />
                    </section>
                  </article>
                </Link>
              </Post>
            )
          })} */}
        </PostsWrapper>
      </Section>
      <Consultation>
        <h3>
          Have a project in mind,
          <br /> but not sure where to start?
        </h3>
        <p>
          Let's talk you through the product that you are hoping to build. We
          can provide you with free expertise and guidance on the technology and
          software architecture that will help you achieve your goals and bring
          the project to fruition.
        </p>
        <ConsulationBtn
          size="lg"
          element="anchor"
          target="_blank"
          href="https://calendly.com/senor-sean/integral-stack-software-consultation"
        >
          Free Consultation
        </ConsulationBtn>
      </Consultation>
      <Section id="about">
        <SectionTitle>About</SectionTitle>
        <AboutWrapper>
          <LeadText>
            Integral Stack was founded in 2020 with the intent of building web
            &amp; mobile software with relentless quality, without losing sight
            of business results. Most recently, Integral Stack has helped a
            enterprise SAAS provider with a department-wide transition to a
            micro frontend architecture in React and has built RESTful API's in
            node.js and Rust for projects in the cryptocurrency space. Please{" "}
            <a
              href="mailto:info@integralstack.io?subject=Portfolio Inquiry"
              rel="nofollow noopener"
              target="_blank"
            >
              reach out
            </a>{" "}
            for work examples from our portfolio.
          </LeadText>
          <div>
            <TeamWrapper>
              <div
                style={{
                  width: "240px",
                  padding: "0 20px",
                  marginRight: "20px",
                }}
              >
                <Headshot
                  href="https://www.linkedin.com/in/seankmichael"
                  rel="nofollow noopener"
                  target="_blank"
                >
                  <img
                    src={meImg}
                    alt="Sean Michael, Owner and Principal Consultant"
                  />
                  <img src={linkedIn} alt="LinkedIn Link" />
                </Headshot>
              </div>
              <AboutText>
                <h3>Sean Michael</h3>
                <span>Owner &amp; Principal Consultant</span>
                <p>
                  With over a decade of experience building software, Sean has
                  had the opportunity to tackle a wide range of challenges
                  across the stack for companies ranging from startups to
                  Fortune 500's. He has a track record for building high-quality
                  digital products, delivering on-time, and leading engineering
                  teams to success. He is well versed in full-stack development
                  and is a{" "}
                  <a
                    href="https://www.youracclaim.com/badges/b75d1946-7a46-459b-bcc5-aa40ebeaa2ff"
                    rel="nofollow noopener"
                    target="_blank"
                  >
                    Certified Kubernetes Application Developer
                  </a>
                  , an{" "}
                  <a
                    href="https://www.youracclaim.com/badges/6c1f9fde-9e71-41cc-ac0d-3bb0189f4d3a"
                    rel="nofollow noopener"
                    target="_blank"
                  >
                    AWS Certified Solutions Architect
                  </a>
                  , and a{" "}
                  <a
                    rel="nofollow noopener"
                    href="https://cryptoconsortium.org/lookup/?token=7ff18f&certification_code=CBP"
                  >
                    Certified Bitcoin Professional
                  </a>
                  .
                </p>
              </AboutText>
            </TeamWrapper>

            <TeamWrapper>
              <div
                style={{
                  width: "240px",
                  padding: "0 20px",
                  marginRight: "20px",
                }}
              >
                <Headshot
                  href="https://www.linkedin.com/in/marlinsarmiento"
                  rel="nofollow noopener"
                  target="_blank"
                >
                  <img
                    src={mayImg}
                    alt="Marlín Javier Sarmiento, Software Engineer"
                  />
                  <img src={linkedIn} alt="LinkedIn Link" />
                </Headshot>
              </div>
              <AboutText>
                <h3>Javier Sarmiento</h3>
                <span>Software Engineer</span>
                <p>
                  Previously a Primary Care Physician in Honduras, Javier is now
                  pursuing his second career as a software developer. He
                  discovered his love for programming while attempting to solve
                  issues that he encountered in the field of medicine, utilizing
                  technology. Over the last 5 years he has built mobile
                  applications for calculating neonatal parenteral nutrition
                  formulations and intravenous drug infusions, among others. He
                  finds a deep satisfaction in solving engineering problems and
                  is currently focused on sharpening his back-end skills.
                </p>
              </AboutText>
            </TeamWrapper>
            <TeamWrapper>
              <div
                style={{
                  width: "240px",
                  padding: "0 20px",
                  marginRight: "20px",
                }}
              >
                <Headshot
                  href="https://www.linkedin.com/in/leonestebangonzalez/"
                  rel="nofollow noopener"
                  target="_blank"
                >
                  <img
                    src={leoImg}
                    alt="León Esteban González, Software Engineer"
                  />
                  <img src={linkedIn} alt="LinkedIn Link" />
                </Headshot>
              </div>
              <AboutText>
                <h3>León Esteban González</h3>
                <span>Software Engineer</span>
                <p>
                  León is a Software Engineer from Medellín, Colombia. He is a
                  highly motivated engineer, specialized in web development. He
                  has a major in Systems Engineering from Universidad EAFIT in
                  Medellín, and a master's degree in Full Stack Web Development
                  from{" "}
                  <a
                    href="https://certtun.vottun.com/badgedetail?id=cef4117e-a55c-4d49-834e-f68d17fd57c6"
                    target="_blank"
                  >
                    ThreePoints Digital School
                  </a>{" "}
                  and the UPC School (Universitat Politècnica de Catalunya) in
                  Barcelona. He enjoys developing mostly in the MERN stack:
                  MongoDB, Express, React.js and Node.js.
                </p>
              </AboutText>
            </TeamWrapper>
          </div>
        </AboutWrapper>
      </Section>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
